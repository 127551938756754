import React from 'react';
import Main from '../containers/Layout';
import bulkPaymentsContent from '../../content/pages/bulk-payments.yml';
import BulkPaymentsContainer from '@/containers/BulkPaymentsContainer';

export interface BulkPaymentsPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    itemsLabel: string;
    advantageItems: {
      item: string;
    }[];
    button: { label: string; url: string };
  };
  viewport2: {
    title: string;
    useCaseCards: {
      label: string;
      title: string;
      text: string;
      image: string;
    }[];
  };
  viewport3: {
    title: string;
    description1: string;
    description2: string;
    boldDescription: string;
    howItWorksCards1: {
      title: string;
      description: string;
      image: string;
    }[];
    howItWorksCards2: {
      label: string;
      title: string;
      description: string;
      image: string;
    }[];
  };
  viewport4: {
    mainTitle: string;
    title: string;
    description1: string;
    subtitle: string;
    description2: string;
  };
  viewport5: {
    title: string;
    description1: string;
    subtitle: string;
    description2: string;
  };
  viewport6: {
    title: string;
    link: {
      text: string;
      url: string;
    };
    description: string;
    paymentMethods: {
      img: string;
    }[];
    title2: string;
    pluginCards: {
      label: string;
      image: string;
    }[];
  };
  viewport7: {
    title: string;
    bannerLabel1: string;
    bannerLabel2: string;
    bannerLabel3: string;
    bannerDescription: string;
    button: {
      label: string;
      url: string;
    };
    bannerLabel: string;
    appStoreButton: {
      url: string;
    };
    googlePlayButton: {
      url: string;
    };
  };
}
const BulkPayments: React.FunctionComponent = () => {
  const {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
  } = bulkPaymentsContent as unknown as BulkPaymentsPageContent;
  return (
    <Main>
      <BulkPaymentsContainer
        type="toolkit"
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
          viewport7,
        }}
      />
    </Main>
  );
};

export default BulkPayments;
