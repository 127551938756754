import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import { BulkPaymentsPageContent } from '@/pages/bulk-payments';
import FirstViewport from '@/components/FirstViewport';
import Section from '@/components/Section';
import {
  Body1,
  Body4,
  Description,
  H1,
  H2,
  H3,
  P,
  StrongInitial,
  Subtitle5,
} from '@/components/Typography';
import TickedItems from '@/components/TickedItems';
import ButtonDefault from '@/components/ButtonDefault';
import Vp1Image from '../assets/img/bulkVp1.png';
import Vp4Image from '../assets/img/bulkVp4.png';
import Vp5Image from '../assets/img/bulkVp5.png';
import Vp6Image from '../assets/img/cbdVp8Bg.png';
import bannerBg from '../assets/img/bannerFenaTerminal.png';
import UseCaseCardBig from '@/components/UseCaseCardBig';
import PayByLinkCard from '@/components/PayByLinkCard';
import FirstViewportMirrored from '@/components/FirstViewportMirrored';
import PluginCard from '@/components/PluginCard';
import BottomMessageType2 from '@/components/BottomMessageType2';
import Banner from '@/components/Banner';
import bannerBgToolkit from '../assets/img/bannerHomePageBg.png';

const FirstViewportWrapper = styled.div`
  background: linear-gradient(123.46deg, #fbfbfd 45.14%, #e7edf3 100.49%);
`;

const SecondViewport = styled(Section)`
  background-color: #fff;
`;

const ThirdViewport = styled(Section)`
  background: linear-gradient(
    180deg,
    rgba(194, 206, 219, 0.5) -46.34%,
    rgba(244, 247, 249, 0.5) 26.53%,
    rgba(244, 247, 249, 0) 100%
  );
  background-color: #fff;
`;

const FourthViewport = styled(FirstViewport)``;

const SixthViewport = styled(Section)`
  padding-bottom: 10rem;
  background-color: #fff;
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1200px) {
    padding: 5rem 2rem 1rem 2rem;
  }
`;

const SeventhViewport = styled(Section)`
  background-color: #fff;
`;

const FirstViewportMirroredWrapper = styled.div`
  padding-bottom: 5rem;
`;

const ViewportsWrapper = styled.div`
  background-color: #fff;
  padding-top: 5rem;
`;

const StyledStrong = styled(StrongInitial)`
  margin-top: 2rem;
`;

const DescriptionWrapper = styled.div`
  margin: 1rem 0 2rem 0;
`;

const StyledSubtitle5 = styled(Subtitle5)`
  margin-top: 1rem;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const Label = styled(H3)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: center;
  }
`;

const StepsDescription = styled(Description)`
  text-align: center;
  &:first-of-type {
    margin-top: 1rem;
  }
`;

const BoldDescriptionCentered = styled(Description)`
  text-align: center;
  font-weight: 500;
`;

const StyledP = styled(P)`
  margin: 1rem 0;
`;

const HowItWorksCardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  flex-wrap: wrap;
`;

const StyledSubtitle5Vp4 = styled(Subtitle5)`
  margin: 2rem 0 1rem 0;
`;

const StyledH2Vp7 = styled(H2)`
  text-align: center;
  margin-bottom: 2rem;
`;

const StyledH2 = styled(H2)``;

const StyledDescription = styled(P)`
  margin: 1.5rem 0;
`;

const PaymentMethodWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 7rem;
  @media (max-width: 1200px) {
    margin-top: 1rem;
  }
`;

const PaymentMethod = styled.div`
  @media (max-width: 1200px) {
    margin-right: 1rem;
    margin-top: 1rem;
  }
`;

const PluginCardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SliderWrapper = styled.div`
  padding: 0 10rem;
  @media (max-width: 1200px) {
    padding: 0;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6rem;
  margin-bottom: 10rem;
`;

const PriceContainer = styled.div`
  display: flex;
  background: linear-gradient(81.7deg, #e7edf3 8.96%, #e4e6f0 104.78%);
  border-radius: 15px;
  border: 1px solid #dbe3eb;
  padding: 1.5rem;
  flex-basis: 20%;
`;

const PriceLeft = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #c2cedb;
  padding-right: 0.5rem;
  flex-basis: 20%;
`;

const PriceRight = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 80%;
  padding-left: 1.5rem;
  @media (max-width: 1200px) {
    padding-left: 1rem;
  }
`;

const Price = styled.p`
  font-size: 30px;
  font-weight: 500;
`;

const StyledSlider = styled(Slider)`
  .rc-slider-handle-dragging {
    box-shadow: 0 0 0 15px #2cd19d1d !important;
  }
  .rc-slider-dot-active {
    border: none;
    background-color: #dbe3eb;
  }
  margin-top: 0.5rem;
`;

const SliderMarkBody4 = styled(Body4)`
  margin-top: 1.5rem;
  @media (max-width: 1200px) {
    font-size: 80%;
  }
`;

const SliderMarkBody4Red = styled(SliderMarkBody4)`
  color: #ef6355;
`;

const RedMarkSpan = styled.span`
  color: #ef6355;
`;

const Link = styled.a`
  color: #38b6ff;
  text-decoration: none;
`;

interface BulkPaymentsProps {
  type?: string;
  content: BulkPaymentsPageContent;
}

const BulkPaymentsContainer: React.FunctionComponent<BulkPaymentsProps> = ({
  type,
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
  },
}) => {
  const trackStyle = [
    {
      background: `linear-gradient(96.17deg, #2CD19E 53.16%, #47EF90 99.47%)`,
      height: `10px`,
    },
  ];

  const railStyle = {
    height: `10px`,
  };

  const handleStyle = [
    {
      border: `none`,
      opacity: `100`,
      backgroundColor: `#2CD19E`,
      top: `5px`,
      width: `20px`,
      height: `20px`,
    },
  ];

  const dotStyle = {
    top: `17px`,
    width: `1px`,
    height: `15px`,
    backgroundColor: `#dbe3eb`,
    border: `none`,
    borderRadius: `initial`,
  };

  const marks = {
    10: (
      <SliderMarkBody4>
        1 payee: <RedMarkSpan>free</RedMarkSpan>
      </SliderMarkBody4>
    ),
    30: <SliderMarkBody4>Up to 10</SliderMarkBody4>,
    50: <SliderMarkBody4>Up to 50</SliderMarkBody4>,
    70: <SliderMarkBody4>Up to 100</SliderMarkBody4>,
    90: <SliderMarkBody4Red>Additional 100</SliderMarkBody4Red>,
  };

  const [priceValue, setPriceValue] = useState(`9.99`);

  const onSliderChangeHandler = (e) => {
    if (e === 10) {
      setPriceValue(`free`);
    } else if (e === 30) {
      setPriceValue(`9.99`);
    } else if (e === 50) {
      setPriceValue(`14.99`);
    } else if (e === 70) {
      setPriceValue(`19.99`);
    } else if (e === 90) {
      setPriceValue(`+ 19.99`);
    }
  };

  return (
    <>
      <FirstViewportWrapper>
        <FirstViewport img={Vp1Image}>
          <H1>
            <span className="accent-text">{viewport1.title1}</span>
            {` `}
            {viewport1.title2}
            {` `}
          </H1>
          <StyledStrong>
            {viewport1.description}
            {` `}
          </StyledStrong>
          <StyledSubtitle5>{viewport1.itemsLabel}</StyledSubtitle5>
          <DescriptionWrapper>
            <TickedItems items={viewport1.advantageItems} />
          </DescriptionWrapper>
          <ButtonDefault to={viewport1.button.url}>
            {viewport1.button.label}
          </ButtonDefault>
        </FirstViewport>
      </FirstViewportWrapper>
      <SecondViewport>
        <Label>{viewport2.title}</Label>
        <CardWrapper>
          {viewport2.useCaseCards.map((item) => (
            <UseCaseCardBig
              key={item.label}
              flexBasis="25%"
              marginBottom="2rem"
              title={item.title}
              label={item.label}
              text={item.text}
              cardImgSrc={item.image}
            />
          ))}
        </CardWrapper>
      </SecondViewport>
      <ThirdViewport>
        <Label>{viewport3.title}</Label>
        <StepsDescription>
          <span className="accent-text-green-bold">
            {viewport3.description1}
          </span>
          {` `}
          {viewport3.description2}
        </StepsDescription>
        <BoldDescriptionCentered>
          {viewport3.boldDescription}
        </BoldDescriptionCentered>
        <HowItWorksCardsWrapper>
          {viewport3.howItWorksCards1.map((item) => (
            <PayByLinkCard
              key={item.title}
              label={item.title}
              text={item.description}
              imageSrc={item.image}
              backgroundColor="#fff"
            />
          ))}
        </HowItWorksCardsWrapper>
        <HowItWorksCardsWrapper>
          {viewport3.howItWorksCards2.map((item) => (
            <PayByLinkCard
              key={item.title}
              greenTitle={item.label}
              label={item.title}
              text={item.description}
              imageSrc={item.image}
              backgroundColor="#fff"
            />
          ))}
        </HowItWorksCardsWrapper>
      </ThirdViewport>

      <ViewportsWrapper>
        <Label>{viewport4.mainTitle}</Label>
        <FourthViewport imgWidth="50%" img={Vp4Image} withRightPadding>
          <H3>{viewport4.title}</H3>
          <StyledP>{viewport4.description1}</StyledP>
          <StyledSubtitle5Vp4>{viewport4.subtitle}</StyledSubtitle5Vp4>
          <P>{viewport4.description2}</P>
        </FourthViewport>
        <FirstViewportMirroredWrapper>
          <FirstViewportMirrored imgWidth="50%" img={Vp5Image} withRightPadding>
            <H3>{viewport5.title}</H3>
            <StyledP>{viewport5.description1}</StyledP>
            <StyledSubtitle5Vp4>{viewport5.subtitle}</StyledSubtitle5Vp4>
            <P>{viewport5.description2}</P>
          </FirstViewportMirrored>
        </FirstViewportMirroredWrapper>
      </ViewportsWrapper>
      <SixthViewport resource={Vp6Image}>
        <StyledH2>{viewport6.title}</StyledH2>

        <StyledDescription>
          {viewport6.description}
          {` `}
          <Link href={viewport6.link.url} target="_blank">
            {viewport6.link.text}
          </Link>
        </StyledDescription>
        <PaymentMethodWrapper>
          {viewport6.paymentMethods.map((item) => (
            <PaymentMethod key={item.img}>
              <img src={item.img} alt="company logo" />
            </PaymentMethod>
          ))}
        </PaymentMethodWrapper>
        <Label>{viewport6.title2}</Label>
        <PluginCardWrapper>
          {viewport6.pluginCards.map((item) => (
            <PluginCard
              key={item.label}
              title={item.label}
              imageSrc={item.image}
            />
          ))}
        </PluginCardWrapper>
      </SixthViewport>

      <SeventhViewport>
        {type === `toolkit` ? (
          <div />
        ) : (
          <>
            <StyledH2Vp7>{viewport7.title}</StyledH2Vp7>
            <SliderWrapper>
              <SliderMarkBody4>Payee</SliderMarkBody4>

              <StyledSlider
                trackStyle={trackStyle}
                railStyle={railStyle}
                handleStyle={handleStyle}
                dotStyle={dotStyle}
                defaultValue={30}
                min={10}
                max={90}
                step={null}
                marks={marks}
                onChange={(e) => {
                  onSliderChangeHandler(e);
                }}
              />
            </SliderWrapper>
            <PriceWrapper>
              <PriceContainer>
                <PriceLeft>
                  <Body1>Price</Body1>
                </PriceLeft>
                <PriceRight>
                  <Price>{priceValue}</Price>
                </PriceRight>
              </PriceContainer>
            </PriceWrapper>
          </>
        )}
        {type === `toolkit` ? (
          <Banner
            bgSrc={bannerBgToolkit}
            label={viewport7.bannerLabel}
            description={viewport7.bannerDescription}
            appStoreButtonUrl={viewport7.appStoreButton.url}
            googlePlayButtonUrl={viewport7.googlePlayButton.url}
          />
        ) : (
          <BottomMessageType2
            accentTextColor="#EF6355"
            bannerLabel1={viewport7.bannerLabel1}
            bannerLabel2={viewport7.bannerLabel2}
            bannerLabel3={viewport7.bannerLabel3}
            bannerDescription={viewport7.bannerDescription}
            button={viewport7.button}
            bannerBg={bannerBg}
          />
        )}
      </SeventhViewport>
    </>
  );
};

export default BulkPaymentsContainer;
