import React from 'react';
import styled from 'styled-components';
import { Body4Bold, P, Subtitle5 } from './Typography';

const Wrapper = styled.div<{ flexBasis?: string; marginBottom?: string }>`
  flex-basis: ${({ flexBasis }) => (flexBasis ? `${flexBasis}` : `30%`)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  margin: 0 1rem;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  border: 1px solid #dbe3eb;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    box-shadow: 0px 4px 30px rgba(194, 206, 219, 0.6);
  }
  @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 2rem;
  }
`;
const Img = styled.img`
  width: 70px;
  height: 70px;
`;
const Info = styled.div`
  min-height: 10rem;
  margin-top: 1rem;
`;
const Title = styled(Subtitle5)`
  margin-bottom: 0.5rem;
`;

const ImageAndLabel = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled(Body4Bold)`
  text-transform: uppercase;
  margin-left: 1rem;
`;

interface UseCaseCardProps {
  label?: string;
  title: string;
  text: string;
  cardImgSrc: string;
  flexBasis?: string;
  marginBottom?: string;
}
const UseCaseCardBig: React.FunctionComponent<UseCaseCardProps> = ({
  title,
  text,
  cardImgSrc,
  flexBasis,
  marginBottom,
  label,
}) => (
  <Wrapper flexBasis={flexBasis} marginBottom={marginBottom}>
    <ImageAndLabel>
      <Img src={cardImgSrc} alt="card logo" />
      <Label className="accent-text-gray">{label}</Label>
    </ImageAndLabel>

    <Info>
      <Title>{title}</Title>
      <P>{text}</P>
    </Info>
  </Wrapper>
);

export default UseCaseCardBig;
